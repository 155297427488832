import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Stack, Button, Checkbox } from "@mui/material";
import PersonIcon from "@mui/icons-material/PersonTwoTone";
import Identity from "./Identity";
import CreditProfile from "./CreditProfile";
import Employer from "./Employer";
import AddEmployer from "./AddEmployer";
import CustomerContactDetails from "./CustomerContactDetails";
import CustomerPersonalDetails from "./CustomerPersonalDetails";
import CustomerPersonalProfile from "./CustomerPersonalProfile";
import CustomerPersonalFinances from "./CustomerPersonalFinances.js";
import CustomerPrivacyAgreement from "./CustomerPrivacyAgreement";
import CustomerFinancialStatement from "./CustomerFinancialStatement";
import CustomerOtherDocuments from "./CustomerOtherDocuments";
import { APPLICATION_TYPES, CLIENT_NAMES, REACT_APP_PLATFORM_NAME } from "../../constants";
// import usePoll from "../../utils/usePoll";
// import CustomerDisclaimer from "./CustomerDisclaimer";
import {
  applicationSelector,
  entitySelector,
  // getDocuments,
  removeCustomer,
  sendCustomerDetailsLinkToCustomer,
  updateCustomer,
} from "../../store/slices/applicationFormSlice";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import { customerSelector } from "../../store/slices/customerSlice";
import { HtmlTooltip } from "../ProductSelector/Product";

const CustomerDetails = React.memo(
  ({
    customer,
    applicationId,
    applicationType,
    index,
    fieldErrors,
    setFieldErrors,
    setIsAcknowledgementAccept,
    isAcknowledgementAccept,
  }) => {
    const dispatch = useDispatch();
    const entity = useSelector(entitySelector);
    const application = useSelector(applicationSelector);
    const permissions = useSelector(userPermissionsSelector);
    const { isCustomerLogin } = useSelector(customerSelector);
    const isDisable = permissions?.application?.edit;
    const servicePermission = permissions?.services;
    const customerId = application?.customers?.[0]?._id;
    // const [delay, setDelay] = useState(null);
    const [confirmRemoveCustomer, setConfirmRemoveCustomer] = useState({});
    // const [timeOfEmploymentTotal, setTimeOfEmploymentTotal] = useState();

    // function handleTimeOfEmploymentTotal() {
    //   const TOET = customer?.employers?.reduce((acc, item) => {
    //     console.log({
    //       acc: item?.timeOfEmployment * 1 ? item?.timeOfEmployment * 1 + acc : acc,
    //       item: item?.timeOfEmployment,
    //     });
    //     return item?.timeOfEmployment * 1 ? item?.timeOfEmployment * 1 + acc : acc;
    //   }, 0);

    //   setTimeOfEmploymentTotal(TOET);
    // }

    // usePoll(async () => {
    //   dispatch(getDocuments(customer._id));
    // }, delay);

    // useEffect(() => {
    //   handleTimeOfEmploymentTotal();
    // }, [customer]);

    const handle = {
      sendFormLinkCustomer: ({ customerId, applicationId, applicationType }) => {
        dispatch(sendCustomerDetailsLinkToCustomer({ customerId, applicationId, applicationType }))
      },
      confirmCustomerChanges: () => {
        // show btn invite customer button (linkSend false, customerSave false)
        // show btn if link send (linkSend true, customerSave false)
        // show confirm btn (linkSend true, customerSave true)
        // If broker want to resend then make all state as default (linkSend false, customerSave false)
        dispatch(
          updateCustomer({
            isCustomerLogin,
            data: {
              customerId: customer._id,
              data: {
                application: applicationId,
                isCustomerDetailsSaved: false, // If broker want to send link again
                isDetailsLinkSend: false, // If broker want to send link again
              },
            },
          }),
        );
      },
    };

    const isSendLinkBtnDisable =
      !customer?.firstName ||
      !customer?.dateOfBirth ||
      customer?.emails?.length <= 0 ||
      customer?.phoneNumbers?.length <= 0;
    const { role, appointmentDate, shares, employers } = customer;
    let temp = applicationId + "-" + index;
    return (
        <Grid
          container
          item
          xs={12}
          style={{
            padding: "0 0 30px",
          }}
        >
          <Grid item xs={12} md={2}>
            <Stack direction="row" style={{ marginBottom: "20px" }}>
              <PersonIcon style={{ marginRight: "15px", color: "#1d4164" }} />
              <Typography fontWeight={600}>
                {applicationType === APPLICATION_TYPES.COMMERCIAL ? "Guarantor" : "Borrower"} #{index + 1}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              {role && (
                <Typography style={{ fontSize: "14px" }}>
                  <strong>Role:</strong> <br />
                  {role}
                </Typography>
              )}
              {appointmentDate && (
                <Typography style={{ fontSize: "14px" }}>
                  <strong>Appointment date:</strong> <br />
                  {appointmentDate}
                </Typography>
              )}
              {shares && entity?.[0]?.shares?.[0]?.numberOfShares && (
                <Typography style={{ fontSize: "14px" }}>
                  <strong>Shares:</strong> <br />
                  {shares}/{entity?.[0]?.shares?.[0]?.numberOfShares} (
                  {((shares / entity?.[0]?.shares?.[0]?.numberOfShares) * 100).toFixed(2)}
                  )%
                </Typography>
              )}
            </Stack>
            {!isCustomerLogin && !confirmRemoveCustomer[customer._id] && (
              <Button
                variant="outlined"
                size="small"
                disabled={!isDisable}
                style={{
                  padding: 0,
                  margin: "10px 0 0 ",
                  // color: "#555",
                }}
                onClick={() => setConfirmRemoveCustomer({ [customer._id]: true })}
              >
                Remove
              </Button>
            )}
            
            {customer?.isDetailsLinkSend && customer?.isCustomerDetailsSaved && (
              <Button
                variant="outlined"
                size="medium"
                style={{
                  padding: 1,
                  margin: "10px 0 0 0",
                }}
                disabled={isSendLinkBtnDisable}
                onClick={handle.confirmCustomerChanges}
              >
                Confirm
              </Button>
            )}

            {confirmRemoveCustomer[customer._id] && (
              <>
                {/* <Stack style={{ margin: "10px 0 0" }} justifyContent="start"> */}
                <Typography>Are you sure?</Typography>
                <Button
                  size="small"
                  variant="link"
                  style={{
                    padding: 0,
                    // marginLeft: "20px",
                    // color: "#1976d2",
                  }}
                  onClick={() => setConfirmRemoveCustomer({ [customer._id]: false })}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="link"
                  style={{
                    padding: 0,
                    // marginLeft: "20px",
                    color: "#d32f2f",
                  }}
                  onClick={() => {
                    dispatch(
                      removeCustomer({
                        customerId: customer._id,
                        applicationId,
                      }),
                    );
                    setConfirmRemoveCustomer({ [customer._id]: false });
                    setFieldErrors({
                      customerTitle: "",
                      firstName: "",
                      lastName: "",
                      IDNumber: "",
                      dateOfBirth: "",
                      IDCardNumber: "",
                      phoneNumber: "",
                      emailAddress: "",
                      address: "",
                    });
                  }}
                >
                  Remove
                </Button>
                {/* </Stack> */}
              </>
            )}

            {!isCustomerLogin && !customer?.isCustomerDetailsSaved &&
              <HtmlTooltip
                tooltipPadding={"20px"}
                disableHoverListener={false}
                title={
                  isSendLinkBtnDisable ? (
                    <React.Fragment>
                      <Typography fontSize="12px">
                        {
                          `Required ${!customer?.firstName ? 'First Name, ' : ''} 
                      ${!customer?.dateOfBirth ? 'Date of Birth, ' : ''} 
                      ${customer?.emails?.length <= 0 ? 'Email, ' : ''}  
                      ${customer?.phoneNumbers?.length <= 0 ? 'Phone number' : ''}`
                        }
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography fontSize="12px">
                        {customer?.isDetailsLinkSend ? 'Waiting for customer to save details' : `Send a link to the customer to complete customer details.`}
                      </Typography>
                    </React.Fragment>
                  )
                }
              >
                <span>
                  <Button
                    variant="outlined"
                    size="medium"
                    style={{
                      padding: 1,
                      margin: "10px 0 0 0",
                    }}
                    disabled={isSendLinkBtnDisable || customer?.isDetailsLinkSend}
                    onClick={() => handle.sendFormLinkCustomer({
                      customerId: customer._id,
                      applicationId,
                      applicationType
                    })}
                  >
                    {customer?.isDetailsLinkSend ? 'In process' : `Invite Customer`}
                  </Button>
                </span>
              </HtmlTooltip>
            }

            {customer?.isDetailsLinkSend && customer?.isCustomerDetailsSaved &&
              <Button
                variant="outlined"
                size="medium"
                style={{
                  padding: 1,
                  margin: "10px 0 0 0",
                }}
                disabled={isSendLinkBtnDisable}
                onClick={handle.confirmCustomerChanges}
              >
                Confirm
              </Button>
            }
          </Grid>

          <Grid container item xs={12} md={10} style={{ padding: "0 0 0 10px" }}>
            {/* Customer Personal Details */}
            <CustomerPersonalDetails
              customer={customer}
              customerId={customerId}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
              applicationId={applicationId}
              key={temp}
            />
            {/* Customer Contact Details */}
            <CustomerContactDetails
              customerId={customerId}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
              customer={customer}
              applicationId={applicationId}
            />
            {customer && !isCustomerLogin && (
              <CustomerPrivacyAgreement customer={customer} applicationId={applicationId} />
            )}
            {customer && servicePermission?.equifax_basic_report && (
              <CreditProfile customer={customer} applicationId={applicationId} />
            )}
            {/* Customer Personal Profile */}
            <CustomerPersonalProfile customer={customer} customerId={customerId} applicationId={applicationId} />
            {/* Customer Personal Finances */}
            <CustomerPersonalFinances
              customer={customer}
              customerIndex={index}
              applicationType={applicationType}
              applicationId={applicationId}
            />
            {(applicationType === APPLICATION_TYPES.CONSUMER || applicationType === APPLICATION_TYPES.PERSONAL) &&
              employers?.map((employer, i) => (
                <Employer
                  key={employer.id}
                  customer={customer}
                  employer={employer}
                  index={i}
                  customerId={customerId}
                  setFieldErrors={setFieldErrors}
                  applicationId={applicationId}
                  customerIndex={index}
                />
              ))}
            {(applicationType === APPLICATION_TYPES.CONSUMER || applicationType === APPLICATION_TYPES.PERSONAL) && (
              // (!customer?.employers?.[0] || timeOfEmploymentTotal < 12) &&
              <AddEmployer customerId={customer._id} numberOfEmployers={customer?.employers?.length} />
            )}
            {customer && servicePermission?.id_verification && !isCustomerLogin && (
              <Identity customer={customer} applicationId={applicationId} />
            )}
            {customer && servicePermission?.bank_statements && !isCustomerLogin && (
              <Grid
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  paddingBottom: "30px",
                }}
              >
                {/* Customer Financial Statement */}
                <CustomerFinancialStatement customer={customer} applicationId={applicationId} />
              </Grid>
            )}
            {/* Customer Other Documents */}
            <CustomerOtherDocuments customer={customer} applicationId={applicationId} />
            {REACT_APP_PLATFORM_NAME === CLIENT_NAMES.BMA && isCustomerLogin && (
              <Stack sx={{ flexDirection: "row", alignItems: "start" }}>
                <Checkbox
                  value={isAcknowledgementAccept}
                  onChange={(event) => setIsAcknowledgementAccept(event.target.checked)}
                />
                <Typography sx={{ fontSize: "0.80rem", marginBottom: "50px", paddingTop: "7px" }}>
                  {customer?.applications?.[0]?.customer_acknowledgement?.acknowledgement_text}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
    );
  },
);

export default CustomerDetails;
