import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Button, FormControl, Grid, MenuItem, Select, Typography, Input, InputAdornment, IconButton, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import _, { startCase, debounce } from "lodash";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { ERRORS_MESSAGE, STATUS_CODE, userType, userTypeOptions } from "../../constants";
import StatusCell from "../../components/User/StatusCell";
import { getAllUsers, updateUserRole, userSelector } from "../../store/slices/userSlice";


export const useStyles = makeStyles(() => ({
  borderRemove: {
    "& fieldset": {
      border: "0px !important",
    },
  },
}));

const UserList = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = React.useState("");
  const user = useSelector(userSelector);
  const usersState = useSelector(userSelector);
  const users = usersState?.users;
  const { permissions, _id } = usersState;
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();


  const handle = {
    getAllUsersFn: (userId, cursor, search) => {
      dispatch(getAllUsers({ userId, cursor, search }));
    },
    handleUserSearch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllUsersFn(user, users?.page, searchTerm);

    },
  };

  const handleUserUpdate = (id, value) => {
    const data = {
      userId: id,
      data: {
        userType: value,
      },
    };
    dispatch(updateUserRole(data)).then(({ payload }) => {
      if (payload?.status_code === STATUS_CODE.success) {
        enqueueSnackbar(payload?.status_message, {
          variant: "success",
          autoHideDuration: 5000,
        });
        const updatedRow = rows?.map((doc) => (doc?.id === id ? { ...doc, role: value } : doc));
        setRows(updatedRow);
      } else {
        enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  const masterColumns = [
    {
      field: "date",
      headerName: "Date",
      type: "string",
      width: 100,

      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
        </div>
      ),
    },
    {
      field: "brokerOrganisation",
      headerName: "Broker",
      width: 250,
      // editable: true,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      // editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      // editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 130,
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 380,
      // editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: permissions?.user?.status_change ? true : false,
      renderCell: ({ row }) => <StatusCell rowInfo={row} rows={rows} setRows={setRows} />,
    },

    {
      field: "roles",
      headerName: "User Roles",
      width: 170,
      // editable: true,
      renderCell: ({ row }) =>
        [userType.master].includes(row?.role) ? (
          <Typography sx={{ textAlign: "center", margin: 2 }}>{startCase(row?.role)}</Typography>
        ) : (
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={row?.role || ""}
              disabled={!permissions?.user?.edit_role}
              className={classes.borderRemove}
              onChange={(event) => handleUserUpdate(row?.id, event.target.value)}
            >
              {userTypeOptions
                .filter((el) => el !== userType.master)
                .map((item) => (
                  <MenuItem
                    value={item}
                    key={item}
                    disabled={item === userType.master} // owner
                  >
                    {startCase(item)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 210,
      renderCell: (params) => (
        user?._id !== params?.id &&
        <Button onClick={() => navigate(`/user/rolepermissions/${params?.id}`)} variant="outlined">
          edit Permission
        </Button>
      ),
    },
  ];

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || applications.nextPage) {
    handleGetAllUsers(user._id, newPage + 1, 10);
    setPage(newPage);
    // }
  };

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(users?.totalDocs || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (users?.totalDocs !== undefined ? users?.totalDocs : prevRowCountState));
  }, [users?.totalDocs, setRowCountState]);

  function handleGetAllUsers(userId, cursor, limit) {
    dispatch(getAllUsers({ userId, cursor, limit }));
  }

  useEffect(() => {
    if (permissions?.user && permissions?.user?.access !== "all" && permissions?.user?.access !== "own") {
      navigate("/404");
    }
  }, [permissions]);

  useEffect(() => {
    if (user?._id) handleGetAllUsers(user._id, users?.page || 1, 10);
  }, [user?._id]);

  useEffect(() => {
    setRows(
      users?.docs?.map((doc) => {
        const date = new Date(doc?.createdAt);
        return {
          id: doc?._id,
          firstName: doc?.firstName,
          lastName: doc?.lastName,
          brokerOrganisation: doc?.organisation?.name,
          phoneNumber: doc?.phoneNumber?.number,
          email: doc?.email?.address,
          userId: doc?._id,
          cursor: users?.page,
          status: _.upperFirst(doc?.status),
          role: doc?.userType,
          date: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
        };
      }),
    );
  }, [users]);

  const debounced = useCallback(debounce(handle.handleUserSearch, 500), []);

  return (
    <Grid container item direction="column" spacing={0} style={{ padding: "20px" }}>
      <Grid
        container
        item
        justifyContent={"space-between"}
        style={{
          paddingBottom: "5px",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Grid item  sx={{ display: "flex", flexDirection: "row" }}>
          <Stack>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                fontWeight: 800,
                letterSpacing: "-0.5px",
                marginRight:"20px"
              }}
            >
              Users
            </Typography>
          </Stack>
          <Stack>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Input
                className="input-border-bottom"
                id="user-search"
                autoComplete="search" // false
                variant="filled"
                placeholder="Search…"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debounced(e.target.value, 1000);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchTerm("");
                          searchTerm !== "" && debounced("", 1000);
                        }}
                        color="secondary"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Stack>
        </Grid>

        {permissions?.user?.invite && (
          <Grid item>
            <Button onClick={() => navigate(`/invite/${_id}`)} variant="outlined">
              Invite Users
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <DataGrid
          autoHeight
          autoPageSize
          pagination
          editMode="cell"
          // onCellClick={(params, event) => {
          //   console.log({ params })
          //   event.defaultMuiPrevented = true;
          //   if (params.field !== "status") navigate(`/ application / ${ params.id } `);
          // }}
          // onRowClick={(event) => {
          //   console.log(event);
          // }}
          rows={rows || []}
          columns={masterColumns}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          style={{ background: "#ffffff", minHeight: "60vh", border: "none" }}
          // initialState={initialState}
          // density="compact"
          pageSize={10}
          columnVisibilityModel={{ action: !!permissions?.user?.permission_edit }}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          // loading={isLoading}
          sx={{
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#999",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#616161",
            },
          }}
        />
      </Grid>
    </Grid>
  );
});

export default UserList;
