import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { APPLICATION_TYPES, CreditScoreTypes, defaultCreditScoreValues } from "../../constants";
import { saveValue } from "../../store/slices/applicationFormSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, scoreId, theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular,
    // scoreId.indexOf(name) === -1
    //   ? theme.typography.fontWeightRegular
    //   : theme.typography.fontWeightMedium,
  };
}

const CreditScoreTypesSelector = React.memo(({ customer, application }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [scoreId, setScoreId] = React.useState([]);
  const [creditScoreTypeOptions, setCreditScoreTypeOptions] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState({
    permissionType: "",
    productDataLevel: "",
  });

  useEffect(() => {
    if (application?.applicationType) {
      const creditScore = CreditScoreTypes[application?.applicationType]
      setCreditScoreTypeOptions(creditScore);

      if (customer?.customerProfile?.scorecardIds) {
        setScoreId(customer?.customerProfile?.scorecardIds);
      } else {
        if (application?.applicationType) {
          const defaultCreditScoreList = []
          creditScore.forEach((score) => {
            if (defaultCreditScoreValues[application?.applicationType].includes(score.value)) {
              defaultCreditScoreList.push(score)
            }
          })

          setScoreId(defaultCreditScoreList)
          // set default creditScore type
          setSelectedType({
            permissionType: defaultCreditScoreList[0]?.permissionType,
            productDataLevel: defaultCreditScoreList[0]?.productDataLevel,
          });
          dispatch(saveValue({ creditScoreTypes: defaultCreditScoreList }));
        }
      }
    }
  }, [application, customer]);

  const handle = {
    getSelectedIndex: (value) => scoreId.findIndex((item) => item.value === value),
    handleChange: (event) => {
      const {
        target: { value },
      } = event;
      if (value?.[0]) {
        setSelectedType({
          permissionType: value[0]?.permissionType,
          productDataLevel: value[0]?.productDataLevel,
        });
      } else if (value?.length <= 0) {
        setSelectedType({
          permissionType: "",
          productDataLevel: "",
        });
      }

      // let typeList = []
      // value?.forEach(type => {
      //   if(type?.value){
      //     typeList.push(type?.value)
      //   }
      // })
      // console.log("typeList", typeList)
      setScoreId(value);
      // setScoreId(
      //   typeof value === 'string' ? value.split(',') : value,
      // );
      dispatch(saveValue({ creditScoreTypes: value }));
    },
    blurFn: () => {
      // const { target } = event;
      // Update list into credit profile
      // dispatch(saveValue({ creditScoreTypes: value }));
    },
  };

  // const getSelectedLabel = (value) => {
  //   return creditScoreTypeOptions?.find(score => score.value === value)?.label
  // }

  const isDisableOption = (score) => {
    if (selectedType?.permissionType !== "") {
      //  return !(selectedType?.permissionType === score?.permissionType && selectedType?.productDataLevel === score?.productDataLevel)
      return !(selectedType?.permissionType === score?.permissionType);
    }
    return false;
  };

  const isDisableRemaining = (score) => {
    const isDisable = isDisableOption(score);
    if (isDisable) return true;
    if (scoreId.length === 0) return false;

    if (!scoreId.some((item) => item.value === score.value) && scoreId.length === 3) {
      return true;
    }
    return false;
  };

  const multipleOptions = (score, scoreId) => {
    if (isDisableOption(score) || scoreId.length === 0) {
      return "";
    } else {
      if (handle.getSelectedIndex(score.value) === 0) {
        return " ( Primary )";
      } else {
        return " ( Secondary )";
      }
    }
  };

  return (
    <React.Fragment>
      {application?.applicationType === APPLICATION_TYPES.COMMERCIAL && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="credit-score-type">Credit Score Type</InputLabel>
          {creditScoreTypeOptions?.length > 0 && (
            <Select
              labelId="credit-score-type"
              id="credit-score-id"
              multiple
              value={scoreId}
              onChange={handle.handleChange}
              onBlur={handle.blurFn}
              input={<OutlinedInput id="select-multiple-chip" label="Credit Score Type" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value?.value} label={value?.label} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {creditScoreTypeOptions.map((score) => (
                <MenuItem
                  key={score.value}
                  value={score}
                  style={getStyles(score.value, scoreId, theme)}
                  disabled={isDisableRemaining(score)}
                >
                  {score.label}
                  {multipleOptions(score, scoreId)}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}
    </React.Fragment>
  );
});

export default CreditScoreTypesSelector;